import React, { useContext, useEffect } from 'react';
import JSZip from 'jszip';
import './setup.css';
import SetupContext from './SetupContext';
import Disclaimer from './sections/Disclaimer';
import MDIconText from '../mdi/MDIconText';
import SnackbarContext from '../snackbar/SnackbarContext';
import Colors from './sections/Colors';
import General from './sections/General';
import FramesSetup from './sections/FramesSetup';
import KuperSetup from './sections/KuperSetup';
import BlueprintSetup from './sections/BlueprintSetup';
import ColorsSetupFile from './files/ColorsSetupFile';
import DashboardSetupFile from './files/DashboardSetupFile';
import FramesSetupFile from './files/FramesSetupFile';
import KuperSetupFile from './files/KuperSetupFile';
import HomeSetupFile from './files/HomeSetupFile';
import BlueprintSetupFile from './files/BlueprintSetupFile';
import StringsSetupFile from './files/StringsSetupFile';

const SetupContent = () => {
  const { setupData: data, termsAccepted } = useContext(SetupContext);
  const { dispatchSnackbar } = useContext(SnackbarContext);
  const { tab: currentTab } = data;

  useEffect(() => {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
  }, []);

  const downloadFiles = () => {
    if (!termsAccepted) {
      dispatchSnackbar({
        text: 'You must read and accept the disclaimer information first',
        style: 'error',
      });
      return;
    }

    dispatchSnackbar({ text: 'Downloading…' });
    const zipFile = new JSZip();
    zipFile.file('colors.xml', ColorsSetupFile.buildFile(data.colors));
    zipFile.file('dashboard_setup.xml', DashboardSetupFile.buildFile(data.general));
    zipFile.file('frames_setup.xml', FramesSetupFile.buildFile(data.frames));
    zipFile.file('kuper_setup.xml', KuperSetupFile.buildFile(data.kuper, data.name));
    zipFile.file('home_setup.xml', HomeSetupFile.buildFile(data['blueprint-home']));
    zipFile.file('blueprint_setup.xml', BlueprintSetupFile.buildFile(data['blueprint-general']));
    zipFile.file('strings.xml', StringsSetupFile.buildFile(data.app));

    zipFile.generateAsync({ type: 'blob' })
      .then((zipBlob) => {
        const linkElement = window.document.createElement('a');
        linkElement.href = URL.createObjectURL(zipBlob);
        linkElement.download
          = `dashbud-${data.app.toString().trim()
          .replace(/\s/g, '-')}-${new Date().toISOString()
          .replace(/\s/g, '')}.zip`;
        window.document.body.appendChild(linkElement);
        linkElement.click();
      })
      // eslint-disable-next-line no-console
      .catch((err) => console.error(err));
  };

  return (
    <>
      <Disclaimer show={currentTab === 0}/>
      <Colors show={currentTab === 1}/>
      <General show={currentTab === 2}/>
      <FramesSetup show={currentTab === 3}/>
      <KuperSetup show={currentTab === 4}/>
      <BlueprintSetup show={currentTab === 5}/>
      <button className={'efab use-manrope'} onClick={downloadFiles}>
        <MDIconText text={'Download'} iconName={'download'} iconSize={24}/>
      </button>
      <br/><br/>
    </>
  );
};

export default SetupContent;
