import React from 'react';
import { Link } from 'react-router-dom';
import { Container } from 'react-bulma-components';
import Toolbar from '../toolbar/Toolbar';
import MDIcon from '../mdi/MDIcon';

const NotFound = () => (
  <>
    <div className={'hero is-fullheight-with-navbar'}>
      <div className={'hero-head'}>
        <Toolbar/>
      </div>
      <div className={'hero-body'}>
        <Container className={'has-text-centered'}>
          <h2>Error 404</h2>
          <p>This site does not exist</p>
          <br/>
          <Link to={'/'} className={'button is-link'}>Back</Link>
        </Container>
      </div>
      <div className={'hero-foot hero-footer use-manrope'}>
        <div className={'container has-text-centered'}
             style={{ marginTop: '1rem', marginBottom: '3rem' }}>
          <p style={{ fontWeight: 600 }}>
            <MDIcon iconName={'code-tags'}/>&nbsp;Developed by&nbsp;
            <a href={'https://jahir.dev'} target={'_blank'} rel={'noopener noreferrer'}>
              Jahir Fiquitiva
            </a>
            &nbsp;&nbsp;
          </p>
          <a href={'https://jahir.dev'} target={'_blank'} rel={'noopener noreferrer'}>
            <img id={'logo'} src={'https://jahir.dev/assets/images/brand/logo-def.svg'}
                 alt={'JF'} height={'24'} width={'24'} style={{ marginTop: '.8rem' }}
                 onError={'this.onerror=null;this.src=\'https://jahir.dev/assets/images/brand/logo-def.png\''}/>
          </a>
        </div>
      </div>
    </div>
  </>
);

export default NotFound;
