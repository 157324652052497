import React, { useContext } from 'react';
import { Columns, Form } from 'react-bulma-components';
import SetupContext from '../../SetupContext';
import CustomCheckbox from '../../checkbox/CustomCheckbox';
import PreviewImg from '../preview/PreviewImg';
import drawerTextsOn from './images/drawer-texts-on.png';
import drawerTextsOff from './images/drawer-texts-off.png';

const setupKey = 'blueprint-general';
const BlueprintGeneralSetup = () => {
  const { setupData: data, dispatchSetupData: dispatchData } = useContext(SetupContext);

  const getValue = (optionKey) => data[setupKey][optionKey];

  const dispatchCheckbox = (checked, option) => {
    dispatchData({ type: setupKey, option, value: checked ? 1 : 0 });
  };

  const dispatchField = (value, option) => {
    dispatchData({ type: setupKey, option, value });
  };

  return (
    <>
      <h4 className={'use-manrope'}>General Setup</h4>
      <br/>
      <Columns>
        <Columns.Column>
          <CustomCheckbox option={'drawer-texts'} label={'Show app info in drawer header'}
                          onChange={dispatchCheckbox} checked={getValue('drawer-texts') === 1}/>
          <PreviewImg image={getValue('drawer-texts') === 1 ? drawerTextsOn : drawerTextsOff}
                      alt={'Drawer preview'}/>
          <br/>
          <CustomCheckbox option={'drawable'} label={'Use drawable.xml to load icons'}
                          onChange={dispatchCheckbox} checked={getValue('drawable') === 1}>
            <p className={'help'}>If <b>not</b> checked, icon_pack.xml will be used instead</p>
          </CustomCheckbox>
          <br/>
          <CustomCheckbox option={'adaptive'} label={'Does your icon pack include adaptive icons?'}
                          onChange={dispatchCheckbox} checked={getValue('adaptive') === 1}/>
          <br/><br/>
          <Form.Field>
            <Form.Label>Email</Form.Label>
            <Form.Control>
              <Form.Input type={'email'} value={getValue('email')}
                          placeholder={'hello.world@mywebsite.com'}
                          onChange={(e) => { dispatchField(e.target.value, 'email'); }}/>
              <p className={'help'}>Used for contact and icons requests</p>
            </Form.Control>
          </Form.Field>
          <Form.Field>
            <Form.Label>Email Subject</Form.Label>
            <Form.Control>
              <Form.Input type={'text'} value={getValue('subject')}
                          placeholder={'General Inquiry'}
                          onChange={(e) => { dispatchField(e.target.value, 'subject'); }}/>
              <p className={'help'}>Used for general inquiries</p>
            </Form.Control>
          </Form.Field>
          <Form.Field>
            <Form.Label>Icons Requests Email Subject</Form.Label>
            <Form.Control>
              <Form.Input type={'text'} value={getValue('request')}
                          placeholder={'Icons Request'}
                          onChange={(e) => { dispatchField(e.target.value, 'request'); }}/>
            </Form.Control>
          </Form.Field>
          <br/><br/>
          <Form.Field>
            <Form.Label>Max apps to request</Form.Label>
            <Form.Control>
              <Form.Input type={'number'} value={getValue('apps')} min={-1}
                          placeholder={'-1'}
                          onChange={(e) => { dispatchField(e.target.value, 'apps'); }}/>
              <p className={'help'}>
                Your users will be able to request max {getValue('apps')} icons/apps at once. -1
                means as many as users want. 0 means none.
              </p>
            </Form.Control>
          </Form.Field>
          <Form.Field>
            <Form.Label>Requests time limit (in minutes)</Form.Label>
            <Form.Control>
              <Form.Input type={'number'} value={getValue('time')} min={-1}
                          placeholder={'-1'}
                          onChange={(e) => { dispatchField(e.target.value, 'time'); }}/>
              <p className={'help'}>
                Your users will be able to request icons/apps every {getValue('time')} minute(s)
                only. 0 or less means they will be able to request as many times they want.
              </p>
            </Form.Control>
          </Form.Field>
          <br/><br/>
          <Form.Field>
            <Form.Label>Arctic Manager API key</Form.Label>
            <Form.Control>
              <Form.Input type={'text'} value={getValue('arctic')} min={-1}
                          placeholder={'123-ABC-456-DEF'}
                          onChange={(e) => { dispatchField(e.target.value, 'arctic'); }}/>
              <p className={'help'}>
                Optional. Get yours at&nbsp;
                <a href={'https://arcticmanager.com'} rel={'noopener noreferrer'}
                   target={'_blank'}>
                  arcticmanager.com
                </a>
              </p>
            </Form.Control>
          </Form.Field>
        </Columns.Column>
        <Columns.Column/>
      </Columns>
    </>
  );
};

export default BlueprintGeneralSetup;
