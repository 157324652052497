import React from 'react';
import BaseCarousel from './BaseCarousel';
import MDIconText from '../mdi/MDIconText';

const getImages = () => {
  const images = [];
  for (let i = 1; i <= 11; i += 1) {
    images.push(`https://raw.githubusercontent.com/jahirfiquitiva/Frames/master/art/${i}.png`);
  }
  return images;
};

const FramesCarousel = () => (
  <>
    <BaseCarousel images={getImages()}/>
    <div className={'buttons'}
         style={{ justifyContent: 'center', marginTop: '1rem' }}>
      <a className={'button'} target={'_blank'} rel={'noopener noreferrer'}
         href={'https://github.com/jahirfiquitiva/Frames'}>
        <MDIconText iconName={'github'} text={'GitHub'}/>
      </a>
      <a className={'button'} target={'_blank'} rel={'noopener noreferrer'}
         href={'https://github.com/jahirfiquitiva/Frames/wiki'}>
        <MDIconText iconName={'help-circle-outline'} text={'Wiki'}/>
      </a>
      <a className={'button'} target={'_blank'} rel={'noopener noreferrer'}
         href={'https://crowdin.com/project/frames/invite'}>
        <MDIconText iconName={'translate'} text={'Translate'}/>
      </a>
      <a className={'button'} target={'_blank'} rel={'noopener noreferrer'}
         href={'https://jahir.dev/gh-releases/frames'}>
        <MDIconText iconName={'download-outline'} text={'Download sample'}/>
      </a>
    </div>
  </>
);

export default FramesCarousel;
