import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import CustomCheckbox from '../checkbox/CustomCheckbox';
import SetupContext from '../SetupContext';

const KuperSetup = ({ show }) => {
  const { setupData: data, dispatchSetupData: dispatchData } = useContext(SetupContext);

  const dispatchCheckbox = (checked, option) => {
    dispatchData({ type: 'kuper', option, value: checked ? 1 : 0 });
  };

  if (!show) return (<></>);

  return (
    <>
      <div className={'has-text-centered'}>
        <h2>Kuper</h2>
      </div>
      <br/><br/><br/>
      <CustomCheckbox option={'kolorette'} label={'Does your app require Kolorette?'}
                      onChange={dispatchCheckbox} checked={data.kuper.kolorette === 1}>
        <p className={'help'}>
          <a target={'_blank'}
             rel={'noopener noreferrer'}
             href={'https://play.google.com/store/apps/details?id=com.arun.themeutil.kolorette'}>
            Kolorette on Google Play
          </a>
        </p>
      </CustomCheckbox>
      <br/>
      <CustomCheckbox option={'media'} label={'Does your app require Media Utilities?'}
                      onChange={dispatchCheckbox} checked={data.kuper.media === 1}>
        <p className={'help'}>
          <a target={'_blank'}
             rel={'noopener noreferrer'}
             href={'https://play.google.com/store/apps/details?id=com.batescorp.notificationmediacontrols.alpha'}>
            Media Utilities on Google Play
          </a>
        </p>
      </CustomCheckbox>
      <br/>
      <CustomCheckbox option={'allow'} label={'Allow saving templates modifications?'}
                      onChange={dispatchCheckbox} checked={data.kuper.allow === 1}>
        <p className={'help'}>This one only applies if you have Zooper templates</p>
      </CustomCheckbox>
    </>
  );
};

KuperSetup.propTypes = {
  show: PropTypes.bool.isRequired,
};

export default KuperSetup;
