import React from 'react';
import PropTypes from 'prop-types';
import MDIcon from '../../mdi/MDIcon';
import MDIconText from '../../mdi/MDIconText';
import './theme-preview.css';

const ThemePreview = ({ colors }) => {
  const parseColor = (preColor) => {
    if (preColor.length <= 7) return preColor;
    return `#${preColor.substring(3)}`;
  };

  const parseOpacity = (preColor) => {
    if (preColor.length <= 7) return 1;
    const newValue = parseInt(preColor.substring(1, 3), 16);
    return newValue / 255;
  };

  const hex2rgba = (hex, alpha = 1) => {
    const [r, g, b] = hex.match(/\w\w/g).map(x => parseInt(x, 16));
    return `rgba(${r},${g},${b},${alpha})`;
  };

  const buildBackgroundStyle = (preColor) => ({
    backgroundColor: hex2rgba(parseColor(preColor), parseOpacity(preColor)),
  });

  const buildTextStyle = (preColor) => ({
    color: hex2rgba(parseColor(preColor), parseOpacity(preColor)),
  });

  const iconsColor = () => {
    if (colors.lightBars) return '#000';
    return '#fff';
  };

  return (
    <div className={'theme-preview'} style={buildBackgroundStyle(colors.background)}>
      <div className={'theme-preview-statusbar'} style={buildBackgroundStyle(colors.primaryDark)}>
        <div><span style={{ fontSize: '12px', color: iconsColor() }}>10:00</span></div>
        <div>
          <MDIcon iconSize={12} iconName={'wifi-strength-4'} style={{ color: iconsColor() }}/>
          <MDIcon iconSize={12} iconName={'network-strength-3'} style={{ color: iconsColor() }}/>
          <MDIcon iconSize={12} iconName={'battery-80'} style={{ color: iconsColor() }}/>
        </div>
      </div>
      <div className={'theme-preview-toolbar'} style={buildBackgroundStyle(colors.primary)}>
        <p style={buildTextStyle(colors.onPrimary)}>Toolbar Title</p>
      </div>
      <p style={buildTextStyle(colors.onBackground)} className={'theme-preview-text-onbg'}>
        Text on background
      </p>
      <div className={'theme-preview-card'} style={buildBackgroundStyle(colors.surface)}>
        <p style={buildTextStyle(colors.onSurface)}>Primary text</p>
        <p style={buildTextStyle(colors.onSurfaceVariant)}>Secondary text</p>
      </div>
      <div className={'theme-preview-snackbar'}
           style={buildBackgroundStyle(colors.snackbarBackgroundColor)}>
        <p style={buildTextStyle(colors.snackbarTextColor)}>Snackbar text</p>
        <p style={buildTextStyle(colors.snackbarButtonColor)}
           className={'snackbar-btn use-manrope'}>Button</p>
      </div>
      <div className={'theme-preview-fab'}>
        <button className={'fab use-manrope'} style={buildBackgroundStyle(colors.accent)}>
          <MDIconText text={'CREATE'} iconName={'plus'} style={buildTextStyle(colors.onAccent)}/>
        </button>
      </div>
      <div className={'theme-preview-navbar'} style={buildBackgroundStyle(colors.surface)}>
        <MDIcon iconSize={20} iconName={'chevron-left'} style={{ color: iconsColor() }}/>
        <MDIcon iconSize={14} iconName={'circle-outline'}
                style={{ color: iconsColor(), marginTop: '.1rem' }}/>
        <MDIcon iconSize={18} iconName={'crop-square'}
                style={{ color: iconsColor(), marginTop: '.05rem' }}/>
      </div>
    </div>
  );
};

ThemePreview.propTypes = {
  colors: PropTypes.object.isRequired,
};

export default ThemePreview;
