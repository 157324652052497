import React, { useContext } from 'react';
import { Columns, Form } from 'react-bulma-components';
import SetupContext from '../../SetupContext';
import CustomCheckbox from '../../checkbox/CustomCheckbox';
import PreviewImg from '../preview/PreviewImg';
import ccc from './images/ccc.png';
import ucc from './images/ucc.png';
import dcc from './images/dcc.png';
import ccu from './images/ccu.png';
import cuc from './images/cuc.png';
import cuu from './images/cuu.png';
import ucu from './images/ucu.png';
import uuc from './images/uuc.png';
import uuu from './images/uuu.png';
import dcu from './images/dcu.png';
import duc from './images/duc.png';
import duu from './images/duu.png';

const setupKey = 'blueprint-home';
const BlueprintHomeSetup = () => {
  const { setupData: data, dispatchSetupData: dispatchData } = useContext(SetupContext);

  const getValue = (optionKey) => data[setupKey][optionKey];

  const dispatchCheckbox = (checked, option) => {
    dispatchData({ type: setupKey, option, value: checked ? 1 : 0 });
  };

  const dispatchField = (value, option) => {
    dispatchData({ type: setupKey, option, value });
  };

  const getHomePreview = () => {
    const actionsOption = getValue('actions').toString();
    const showOverview = getValue('overview');
    const showQuickApply = getValue('apply');
    switch (actionsOption) {
      case '0': {
        if (showOverview) {
          return showQuickApply ? cuu : cuc;
        }
        return showQuickApply ? ccu : ccc;
      }
      case '2': {
        if (showOverview) {
          return showQuickApply ? duu : duc;
        }
        return showQuickApply ? dcu : dcc;
      }
      default: {
        if (showOverview) {
          return showQuickApply ? uuu : uuc;
        }
        return showQuickApply ? ucu : ucc;
      }
    }
  };

  return (
    <>
      <h4 className={'use-manrope'}>Home Setup</h4>
      <br/>
      <Columns>
        <Columns.Column>
          <PreviewImg image={getHomePreview()} alt={'Blueprint home preview'}/>
          <Form.Field>
            <Form.Control>
              <p className={'help'}>Actions style</p>
              <Form.Select value={getValue('actions')} className={'is-small'}
                           onChange={(e) => { dispatchField(e.target.value, 'actions'); }}>
                <option value={0}>Hidden</option>
                <option value={1}>Small</option>
                <option value={2}>Big</option>
              </Form.Select>
            </Form.Control>
          </Form.Field>
          <CustomCheckbox option={'overview'} label={'Show overview/summary'}
                          onChange={dispatchCheckbox} checked={getValue('overview') === 1}/>
          <CustomCheckbox option={'apply'} label={'Show apply button text'}
                          onChange={dispatchCheckbox} checked={getValue('apply') === 1}/>
        </Columns.Column>
      </Columns>
    </>
  );
};

export default BlueprintHomeSetup;
