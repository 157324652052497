import React from 'react';
import PropTypes from 'prop-types';
import './screenshot.css';

const Screenshot = ({ imageUrl, alt, cornerRadius = 8 }) => (
  <div className={'screenshot'}>
    <img src={imageUrl} alt={alt} style={{ borderRadius: cornerRadius }}/>
  </div>
);

Screenshot.propTypes = {
  imageUrl: PropTypes.string.isRequired,
  alt: PropTypes.string,
  onClick: PropTypes.func,
  hide: PropTypes.bool,
  cornerRadius: PropTypes.number,
};

export default Screenshot;
