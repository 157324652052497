import React from 'react';
import PropTypes from 'prop-types';
import MDIcon from './MDIcon';

const MDIconText = ({
  text, iconName, iconSize, className,
  upperCase = false, bold = false, textPosition = 'right', onClick, style,
}) => {
  const renderText = () => {
    const actualText = upperCase ? (text || '').toUpperCase() : (text || '');
    if (bold) return (<b>{actualText}</b>);
    return (<>{actualText}</>);
  };

  if (textPosition === 'left') {
    return (
      <p>
        {renderText()}&nbsp;&nbsp;<MDIcon iconSize={iconSize}
                                          iconName={iconName || ''}
                                          onClick={onClick}/>
      </p>
    );
  }

  return (
    <div className={`mdi-icon-text ${className}`} style={style}>
      {iconName.length > 0
        ? <><MDIcon iconSize={iconSize} iconName={iconName || ''}/>&nbsp;&nbsp;</>
        : <></>}{renderText()}
    </div>
  );
};

MDIconText.propTypes = {
  text: PropTypes.string.isRequired,
  iconName: PropTypes.string.isRequired,
  iconSize: PropTypes.number,
  className: PropTypes.string,
  upperCase: PropTypes.bool,
  bold: PropTypes.bool,
  textPosition: PropTypes.oneOf(['right', 'left']),
  onClick: PropTypes.func,
  style: PropTypes.object,
};

export default MDIconText;
