import React from 'react';
import PropTypes from 'prop-types';
import ColorPicker from 'rc-color-picker';
import 'rc-color-picker/assets/index.css';

const ColorButton = ({
  title = '', color = '#fff', onColorChanged, canShowAlpha = true,
}) => {
  const handleColorChange = (colors) => {
    const alphaHex = Math.floor((colors.alpha * 255) / 100).toString(16);
    onColorChanged(`#${alphaHex}${colors.color.substring(1)}`);
  };

  const parseColor = (preColor) => {
    if (preColor.length <= 7) return preColor;
    return `#${preColor.substring(3)}`;
  };

  const parseOpacity = (preColor) => {
    if (preColor.length <= 7) return 1;
    const newValue = parseInt(preColor.substring(1, 3), 16);
    return newValue / 255;
  };

  return (
    <>
      <div className={'color-btn'}>
        <p><b>{title} Color</b></p>
        <ColorPicker color={parseColor(color)}
                     alpha={parseOpacity(color) * 100}
                     onChange={handleColorChange} enableAlpha={canShowAlpha}
                     animation={'slide-up'}>
          <button className={'button'}>
            <span className={'mdi mdi-24px mdi-square'}
                  style={{ color: parseColor(color), opacity: parseOpacity(color) }}/>&nbsp;{color}
          </button>
        </ColorPicker>
      </div>
    </>
  );
};

ColorButton.propTypes = {
  title: PropTypes.string.isRequired,
  color: PropTypes.string.isRequired,
  onColorChanged: PropTypes.func.isRequired,
  canShowAlpha: PropTypes.bool,
};

export default ColorButton;
