import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import ThemePreview from './ThemePreview';
import ColorButton from './ColorButton';
import SetupContext from '../SetupContext';

const ThemeColors = ({ name }) => {
  const { setupData, dispatchSetupData } = useContext(SetupContext);

  const dispatchColor = (colorName, value) => {
    dispatchSetupData({
      type: 'color', theme: name.toLowerCase(), name: colorName, value,
    });
  };

  return (
    <>
      <h4 className={'has-text-centered'}>
        {name.toString().toLowerCase() === 'amoled' ? 'Amoled / Black' : name} Theme
      </h4>
      <ThemePreview colors={setupData.colors[name.toLowerCase()]}/>
      <p style={{ fontSize: '.8rem' }}>
        <b>* Some transparency values might NOT work in the real app</b>
      </p>
      <br/>
      <ColorButton title={'Primary Dark'}
                   onColorChanged={(color) => { dispatchColor('primaryDark', color); }}
                   color={setupData.colors[name.toLowerCase()].primaryDark}/>
      <ColorButton title={'Primary'}
                   onColorChanged={(color) => { dispatchColor('primary', color); }}
                   color={setupData.colors[name.toLowerCase()].primary}/>
      <ColorButton title={'On Primary'}
                   onColorChanged={(color) => { dispatchColor('onPrimary', color); }}
                   color={setupData.colors[name.toLowerCase()].onPrimary}/>
      <ColorButton title={'Accent'}
                   onColorChanged={(color) => { dispatchColor('accent', color); }}
                   color={setupData.colors[name.toLowerCase()].accent}/>
      <ColorButton title={'On Accent'}
                   onColorChanged={(color) => { dispatchColor('onAccent', color); }}
                   color={setupData.colors[name.toLowerCase()].onAccent}/>
      <ColorButton title={'Surface'}
                   onColorChanged={(color) => { dispatchColor('surface', color); }}
                   color={setupData.colors[name.toLowerCase()].surface}/>
      <ColorButton title={'On Surface'}
                   onColorChanged={(color) => { dispatchColor('onSurface', color); }}
                   color={setupData.colors[name.toLowerCase()].onSurface}/>
      <ColorButton title={'On Surface Variant'}
                   onColorChanged={(color) => { dispatchColor('onSurfaceVariant', color); }}
                   color={setupData.colors[name.toLowerCase()].onSurfaceVariant}/>
      <ColorButton title={'Background'}
                   onColorChanged={(color) => { dispatchColor('background', color); }}
                   color={setupData.colors[name.toLowerCase()].background}/>
      <ColorButton title={'On Background'}
                   onColorChanged={(color) => { dispatchColor('onBackground', color); }}
                   color={setupData.colors[name.toLowerCase()].onBackground}/>
      <ColorButton title={'Snackbar Background'}
                   onColorChanged={(color) => { dispatchColor('snackbarBackgroundColor', color); }}
                   color={setupData.colors[name.toLowerCase()].snackbarBackgroundColor}/>
      <ColorButton title={'Snackbar Text'}
                   onColorChanged={(color) => { dispatchColor('snackbarTextColor', color); }}
                   color={setupData.colors[name.toLowerCase()].snackbarTextColor}/>
      <ColorButton title={'Snackbar Button'}
                   onColorChanged={(color) => { dispatchColor('snackbarButtonColor', color); }}
                   color={setupData.colors[name.toLowerCase()].snackbarButtonColor}/>
    </>
  );
};

ThemeColors.propTypes = {
  name: PropTypes.string.isRequired,
};

export default ThemeColors;
