/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';

const BaseCarousel = ({ images, title }) => {
  const [currentImage, setCurrentImage] = useState(0);
  const [hide, setHide] = useState(false);

  const imageUrl = images ? images[currentImage] || '' : '';

  const goNext = () => {
    setHide(true);
    setTimeout(() => {
      setCurrentImage((current) => (current >= images.length - 1 ? 0 : current + 1));
    }, 100);
  };

  useEffect(() => {
    const changeImage = setInterval(() => { goNext(); }, 5000);
    return () => clearInterval(changeImage);
    // eslint-disable-next-line
  }, []);

  return (
    <div className={'screenshot has-same-height'}
         onClick={goNext} style={{ cursor: 'pointer', borderRadius: 8 }}>
      {hide ? <p>Loading…</p> : <></>}
      <img src={imageUrl}
           alt={`${title || 'App'}-${currentImage}`}
           className={hide ? 'hide' : ''}
           onLoad={() => {
             setTimeout(() => {
               setHide(false);
             }, 100);
           }}
           style={{ borderRadius: 8 }}/>
    </div>
  );
};

export default BaseCarousel;
