import React from 'react';
import Toolbar from '../toolbar/Toolbar';
import Preview from '../main/preview';
import Frames from '../main/frames';
import Kuper from '../main/kuper';
import Blueprint from '../main/blueprint';
import MDIcon from '../mdi/MDIcon';

const MainSite = () => (
  <>
    <section className={'hero is-fullheight-with-navbar'} id={'top'}>
      <div className={'hero-head'}>
        <Toolbar/>
      </div>
      <div className={'hero-body'}>
        <Preview/>
      </div>
    </section>
    <hr style={{ background: 'rgba(32,109,143,.25)' }} id={'frames'}/>
    <section className={'hero is-fullheight'} style={{ background: '#ebf8fd' }}>
      <div className={'hero-body'}>
        <Frames/>
      </div>
    </section>
    <hr style={{ background: 'rgba(61,69,115,.25)' }} id={'kuper'}/>
    <section className={'hero is-fullheight'} style={{ background: '#f0f1f9' }}>
      <div className={'hero-body'}>
        <Kuper/>
      </div>
    </section>
    <hr style={{ background: 'rgba(46,83,144,.25)' }} id={'blueprint'}/>
    <section className={'hero is-fullheight'} style={{ background: '#edf3fe' }}>
      <div className={'hero-body'}>
        <Blueprint/>
      </div>
      <div className={'hero-foot hero-footer use-manrope'}>
        <div className={'container has-text-centered'}
             style={{ marginTop: '1rem', marginBottom: '2rem' }}>
          <p style={{ fontWeight: 600 }}>
            <MDIcon iconName={'code-tags'}/>&nbsp;Developed by&nbsp;
            <a href={'https://jahir.dev'} target={'_blank'} rel={'noopener noreferrer'}>
              Jahir Fiquitiva
            </a>
            &nbsp;&nbsp;
          </p>
          <a href={'https://jahir.dev'} target={'_blank'} rel={'noopener noreferrer'}>
            <img id={'logo'} src={'https://jahir.dev/assets/images/brand/logo-def.svg'}
                 alt={'JF'} height={'24'} width={'24'} style={{ marginTop: '.8rem' }}
                 onError={'this.onerror=null;this.src=\'https://jahir.dev/assets/images/brand/logo-def.png\''}/>
          </a>
        </div>
      </div>
    </section>
  </>
);

export default MainSite;
