import React from 'react';
import { CookiesProvider } from 'react-cookie';
import SetupContent from '../setup/SetupContent';
import SetupDataHolder from '../setup/SetupDataHolder';
import SetupToolbar from '../toolbar/SetupToolbar';
import MDIcon from '../mdi/MDIcon';
import SetupTabs from '../setup/SetupTabs';

const Setup = () => (
  <>
    <section className={'hero is-fullheight-with-navbar setup'} id={'top'}>
      <CookiesProvider>
        <SetupDataHolder>
          <div className={'hero-head'}>
            <SetupToolbar/>
          </div>
          <div className={'hero-body'}>
            <div className={'container'}>
              <div className={'is-hidden-desktop'}>
                <SetupTabs/>
                <p className={'help has-text-centered mobile-warning'}>
                  This site is better seen and used on desktop&nbsp;
                  <span role={'img'} aria-label={'grimacing emoji'}>😬</span>
                </p>
              </div>
              <SetupContent/>
            </div>
          </div>
          <div className={'hero-foot hero-footer use-manrope'}>
            <div className={'container has-text-centered'}
                 style={{ marginTop: '1rem', marginBottom: '3rem' }}>
              <div className={'is-hidden-desktop'}>
                <SetupTabs/>
                <p className={'help has-text-centered mobile-warning'}>
                  This site is better seen and used on desktop&nbsp;
                  <span role={'img'} aria-label={'grimacing emoji'}>😬</span>
                </p>
              </div>
              <p style={{ fontWeight: 600 }}>
                <MDIcon iconName={'code-tags'}/>&nbsp;Developed by&nbsp;
                <a href={'https://jahir.dev'} target={'_blank'} rel={'noopener noreferrer'}>
                  Jahir Fiquitiva
                </a>
                &nbsp;&nbsp;
              </p>
              <a href={'https://jahir.dev'} target={'_blank'} rel={'noopener noreferrer'}>
                <img id={'logo'} src={'https://jahir.dev/assets/images/brand/logo-def.svg'}
                     alt={'JF'} height={'24'} width={'24'} style={{ marginTop: '.8rem' }}
                     onError={'this.onerror=null;this.src=\'https://jahir.dev/assets/images/brand/logo-def.png\''}/>
              </a>
              <br/><br/>
            </div>
          </div>
        </SetupDataHolder>
      </CookiesProvider>
    </section>
  </>
);

export default Setup;
