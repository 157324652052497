import React from 'react';
import { Columns, Container } from 'react-bulma-components';
import FramesCarousel from '../carousel/FramesCarousel';
import FramesFeatures from '../features/FramesFeatures';
import './app-title.css';

const Frames = () => (
  <Container>
    <div className={'app-title'}>
      <img className={'app-logo'} alt={'FramesSetup Logo'}
           src={'https://raw.githubusercontent.com/jahirfiquitiva/Frames/master/art/app_logo.png'}/>
      <h3>Frames</h3>
    </div>
    <Columns>
      <Columns.Column>
        <FramesCarousel/>
      </Columns.Column>
      <Columns.Column>
        <FramesFeatures/>
      </Columns.Column>
    </Columns>
    <br/>
  </Container>
);

export default Frames;
