class ColorsSetupFile {
  static buildFile(colors) {
    const { light, dark, amoled } = colors;
    return `<?xml version="1.0" encoding="utf-8"?>
<resources>
    <!-- Light Theme Colors -->
    <color name="primary">${light.primary}</color>
    <color name="primaryDark">${light.primaryDark}</color>
    <color name="onPrimary">${light.onPrimary}</color>

    <color name="accent">${light.accent}</color>
    <color name="onAccent">${light.onAccent}</color>

    <color name="surface">${light.surface}</color>
    <color name="onSurface">${light.onSurface}</color>
    <color name="onSurfaceVariant">${light.onSurfaceVariant}</color>

    <color name="background">${light.background}</color>
    <color name="onBackground">${light.onBackground}</color>

    <color name="snackbarBackgroundColor">${light.snackbarBackgroundColor}</color>
    <color name="snackbarTextColor">${light.snackbarTextColor}</color>
    <color name="snackbarButtonColor">${light.snackbarButtonColor}</color>

    <!-- Dark Theme Colors -->
    <color name="darkThemePrimary">${dark.primary}</color>
    <color name="darkThemePrimaryDark">${dark.primaryDark}</color>
    <color name="darkThemeOnPrimary">${dark.onPrimary}</color>

    <color name="darkThemeAccent">${dark.accent}</color>
    <color name="darkThemeOnAccent">${dark.onAccent}</color>

    <color name="darkThemeSurface">${dark.surface}</color>
    <color name="darkThemeOnSurface">${dark.onSurface}</color>
    <color name="darkThemeOnSurfaceVariant">${dark.onSurfaceVariant}</color>

    <color name="darkThemeBackground">${dark.background}</color>
    <color name="darkThemeOnBackground">${dark.onBackground}</color>

    <color name="darkThemeSnackbarBackgroundColor">${dark.snackbarBackgroundColor}</color>
    <color name="darkThemeSnackbarTextColor">${dark.snackbarTextColor}</color>
    <color name="darkThemeSnackbarButtonColor">${dark.snackbarButtonColor}</color>

    <!-- Amoled Theme Colors -->
    <color name="amoledThemePrimary">${amoled.primary}</color>
    <color name="amoledThemePrimaryDark">${amoled.primaryDark}</color>
    <color name="amoledThemeOnPrimary">${amoled.onPrimary}</color>

    <color name="amoledThemeAccent">${amoled.accent}</color>
    <color name="amoledThemeOnAccent">${amoled.onAccent}</color>

    <color name="amoledThemeSurface">${amoled.surface}</color>
    <color name="amoledThemeOnSurface">${amoled.onSurface}</color>
    <color name="amoledThemeOnSurfaceVariant">${amoled.onSurfaceVariant}</color>

    <color name="amoledThemeBackground">${amoled.background}</color>
    <color name="amoledThemeOnBackground">${amoled.onBackground}</color>

    <color name="amoledThemeSnackbarBackgroundColor">${amoled.snackbarBackgroundColor}</color>
    <color name="amoledThemeSnackbarTextColor">${amoled.snackbarTextColor}</color>
    <color name="amoledThemeSnackbarButtonColor">${amoled.snackbarButtonColor}</color>

</resources>`;
  }
}

export default ColorsSetupFile;
