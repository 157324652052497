import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { Form } from 'react-bulma-components';
import CustomCheckbox from '../checkbox/CustomCheckbox';
import SetupContext from '../SetupContext';
import PreviewImg from './preview/PreviewImg';
import wallsNormal from './frames-images/frames-walls-normal.png';
import wallsColored from './frames-images/frames-walls-colored.png';
import collsColored from './frames-images/frames-colls-colored.png';
import collsColoredFilled from './frames-images/frames-colls-colored-filled.png';
import collsNormal from './frames-images/frames-colls-normal.png';
import collsNormalFilled from './frames-images/frames-colls-normal-filled.png';
import paletteOn from './frames-images/frames-palette.png';
import paletteOff from './frames-images/frames-palette-off.png';

const FramesSetup = ({ show }) => {
  const { setupData: data, dispatchSetupData: dispatchData } = useContext(SetupContext);

  const dispatchCheckbox = (checked, option) => {
    dispatchData({ type: 'frames', option, value: checked ? 1 : 0 });
  };

  const getCollectionsPreview = () => {
    if (data.frames.filled) {
      return data.frames.colored ? collsColoredFilled : collsNormalFilled;
    }
    return data.frames.colored ? collsColored : collsNormal;
  };

  if (!show) return (<></>);

  return (
    <>
      <div className={'has-text-centered'}>
        <h2>Frames</h2>
      </div>
      <br/><br/><br/>
      <Form.Field>
        <Form.Label>Wallpapers JSON URL</Form.Label>
        <Form.Control>
          <Form.Input type={'text'} value={data.frames.url}
                      placeholder={'https://mywebsite.com/wallpapers.json'}
                      onChange={(e) => {
                        dispatchData({ type: 'frames', option: 'url', value: e.target.value });
                      }}/>
          <p className={'help'}>
            Please ensure your JSON has the&nbsp;
            <a href={'https://github.com/jahirfiquitiva/Frames/wiki/Setting-up-your-json-file#json-format'}
               target={'_blank'} rel={'noopener noreferrer'}>
              right format
            </a>
            &nbsp;first. You can validate it at&nbsp;
            <a href={'https://jsonlint.com/'}
               target={'_blank'} rel={'noopener noreferrer'}>
              JSON lint
            </a> too.
          </p>
        </Form.Control>
      </Form.Field>
      <br/>
      <CustomCheckbox option={'immediate'} label={'Allow immediate downloads'}
                      onChange={dispatchCheckbox} checked={data.frames.immediate === 1}>
        <p className={'help'}>If <b>not</b> checked, users will have to wait 3 hours before being
                              able to download wallpapers.</p>
      </CustomCheckbox>
      <br/>
      <CustomCheckbox option={'wifiOnlyDownload'} label={'Download wallpapers using Wi-Fi only (by default)'}
                      onChange={dispatchCheckbox} checked={data.frames.wifiOnlyDownload === 1}>
        <p className={'help'}>If <b>not</b> checked, users, by default, will be allowed to download wallpapers using mobile data too.</p>
      </CustomCheckbox>
      <br/>
      <CustomCheckbox option={'colored'} label={'Use colored tiles'}
                      onChange={dispatchCheckbox} checked={data.frames.colored === 1}/>
      <PreviewImg image={data.frames.colored ? wallsColored : wallsNormal}
                  alt={'Wallpapers preview'}/>
      <br/>
      <CustomCheckbox option={'filled'} label={'Use filled collection previews'}
                      onChange={dispatchCheckbox} checked={data.frames.filled === 1}/>
      <PreviewImg image={getCollectionsPreview()} alt={'Collections preview'}/>
      <br/>
      <CustomCheckbox option={'palette'} label={'Show palette colors in full screen view'}
                      onChange={dispatchCheckbox} checked={data.frames.palette === 1}/>
      <PreviewImg image={data.frames.palette ? paletteOn : paletteOff} alt={'Palette preview'}/>
    </>
  );
};

FramesSetup.propTypes = {
  show: PropTypes.bool.isRequired,
};

export default FramesSetup;
