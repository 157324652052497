import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import './preview.css';

const PreviewImg = ({ image, alt }) => {
  const [loaded, setLoaded] = useState(false);

  useMemo(() => {
    setLoaded(false);
    // eslint-disable-next-line
  }, [image]);

  return (
    <>
      <a href={image} target={'_blank'} rel={'noopener noreferrer'}>
        <img src={image} onLoad={() => { setLoaded(true); }}
             width={150} alt={alt || 'Preview'}
             className={`option-preview${loaded ? ' loaded' : ''}`}/>
      </a>
      <br/><br/>
    </>
  );
};

PreviewImg.propTypes = {
  image: PropTypes.any.isRequired,
  alt: PropTypes.string,
};

export default PreviewImg;
