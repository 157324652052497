import React, { useContext, useMemo, useState } from 'react';
import SnackbarContext from './SnackbarContext';
import MDIcon from '../mdi/MDIcon';
import './snackbar.css';

const Snackbar = () => {
  const {
    show, text, style, duration, dispatchSnackbar: dispatch,
  } = useContext(SnackbarContext);

  const [snackTimeout, setSnackTimeout] = useState(null);

  useMemo(() => {
    if (text.length > 0) {
      if (show) {
        let actualDuration = 3500;
        if (typeof duration !== 'undefined' && duration !== null) {
          actualDuration = duration;
        }
        if (actualDuration > 0) {
          window.clearTimeout(snackTimeout);
          setSnackTimeout(window.setTimeout(() => {
            dispatch({ type: 'hide' });
          }, actualDuration));
        }
      } else {
        window.clearTimeout(snackTimeout);
        setSnackTimeout(window.setTimeout(() => {
          dispatch({ type: 'removeText' });
        }, 750));
      }
    }
    // eslint-disable-next-line
  }, [show, text, duration, dispatch]);

  const getStyleClassName = () => {
    switch (style) {
      case 'warn':
      case 'warning':
        return 'is-warning';
      case 'error':
        return 'is-error';
      default:
        return '';
    }
  };

  const hideClick = () => {
    dispatch({ type: 'hide' });
  };

  const renderContent = () => {
    const portions = text.split(/\r?\n|\n/).map(it => it.trim());
    return (
      <>
        <div>
          {portions.map((it, i) => {
            if (it.length > 0) return (<p key={i}>{it}</p>);
            return (<></>);
          })}
        </div>
        <MDIcon iconName={'close'} onClick={hideClick}/>
      </>
    );
  };

  if (text.length > 0) {
    return (
      <div className={`snackbar ${show ? 'show' : 'hidden'} ${getStyleClassName()}`}
           data-text={text}>
        {renderContent()}
      </div>
    );
  }
  return (<></>);
};

export default Snackbar;
