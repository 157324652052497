import React from 'react';
import PropTypes from 'prop-types';
import './custom-checkbox.css';

const CustomCheckbox = ({
  label, option, checked, onChange, children,
}) => {
  const setChecked = (e) => {
    onChange(e.target.checked, option);
  };

  return (
    <div>
      <label className={'pure-material-checkbox'}>
        <input type={'checkbox'} onChange={setChecked} checked={checked}/>
        <span>{label}</span>
      </label>
      {children}
    </div>
  );
};

CustomCheckbox.propTypes = {
  label: PropTypes.string.isRequired,
  option: PropTypes.string.isRequired,
  checked: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  children: PropTypes.any,
};

export default CustomCheckbox;
