import React from 'react';
import PropTypes from 'prop-types';
import BlueprintHomeSetup from './blueprint/BlueprintHomeSetup';
import BlueprintGeneralSetup from './blueprint/BlueprintGeneralSetup';

const BlueprintSetup = ({ show }) => {
  if (!show) return (<></>);
  return (
    <>
      <div className={'has-text-centered'}>
        <h2>Blueprint</h2>
      </div>
      <br/><br/><br/>
      <BlueprintGeneralSetup/>
      <br/><br/>
      <BlueprintHomeSetup/>
    </>
  );
};

BlueprintSetup.propTypes = {
  show: PropTypes.bool.isRequired,
};

export default BlueprintSetup;
