/* eslint-disable max-len */
class FramesSetupFile {
  static buildFile(setup) {
    return `<?xml version="1.0" encoding="utf-8"?><!--suppress CheckTagEmptyBody -->
<resources xmlns:tools="http://schemas.android.com/tools" tools:ignore="MissingTranslation">

    <!--    This is the link for the JSON file where you have listed your wallpapers -->
    <string name="json_url">"${setup.url || ''}"</string>

    <!--
    Enable immediate download
    When set to false, users must wait 3 hours after installing the app to be able to download
    any wallpapers
    -->
    <bool name="allow_immediate_downloads">${setup.immediate ? 'true' : 'false'}</bool>

    <!-- Choose whether you want colored tiles by default -->
    <bool name="enable_colored_tiles">${setup.colored ? 'true' : 'false'}</bool>

    <!-- Choose whether you want filled collection preview by default -->
    <bool name="enable_filled_collection_preview">${setup.filled ? 'true' : 'false'}</bool>

    <!-- Choose whether you want to show wallpaper palette colors or not -->
    <bool name="show_wallpaper_palette_details">${setup.palette ? 'true' : 'false'}</bool>

    <!--
    Show a custom image as placeholder while loading wallpapers or collections
    Use drawable name without extensions
    -->
    <string name="wallpapers_placeholder" translatable="false"></string>
    <string name="collections_placeholder" translatable="false"></string>
</resources>`;
  }
}

export default FramesSetupFile;
