import React, { useContext, useEffect, useMemo, useReducer } from 'react';
import PropTypes from 'prop-types';
import { useCookies } from 'react-cookie';
import SetupContext from './SetupContext';
import defaultSetupData from './default-setup-data.json';
import SnackbarContext from '../snackbar/SnackbarContext';

const setupDataReducer = (data, action) => {
  switch (action.type) {
    case 'tab':
      return { ...data, tab: action.payload };
    case 'warn':
      return { ...data, warn: action.payload };
    case 'app':
      return { ...data, app: action.payload };
    case 'name':
      return { ...data, name: action.payload };
    case 'color': {
      const prevColors = data.colors[action.theme];
      const newColor = { ...prevColors };
      newColor[action.name] = action.value;
      const newTheme = { ...data.colors };
      newTheme[action.theme] = newColor;
      return { ...data, colors: newTheme };
    }
    case 'general':
    case 'frames':
    case 'kuper':
    case 'blueprint':
    case 'blueprint-general':
    case 'blueprint-home': {
      const preData = { ...data[action.type] };
      preData[action.option] = action.value;
      const newData = { ...data };
      newData[action.type] = preData;
      return { ...newData };
    }
    case 'set':
      return { ...action.payload };
    default:
      return { ...data };
  }
};

const termsAcceptedReducer = (data, action) => {
  return (action.payload || action).toString().toLowerCase() === 'true' || false;
};

const SetupDataHolder = ({ children }) => {
  const [cookies, setCookie, removeCookie] = useCookies(['data']);
  const [setupData, dispatchSetupData] = useReducer(setupDataReducer, defaultSetupData);
  const [termsAccepted, setTermsAccepted] = useReducer(termsAcceptedReducer, false);
  const { dispatchSnackbar } = useContext(SnackbarContext);

  const showCookiesWarn = () => {
    dispatchSnackbar({ text: 'This site uses cookies to save your setup only' });
    dispatchSetupData({ type: 'warn', payload: 0 });
  };

  const resetSetupData = () => {
    removeCookie('data', { path: '/' });
    dispatchSetupData({ type: 'set', payload: defaultSetupData });
  };

  useEffect(() => {
    const currentSetupData = cookies.data || null;
    if (typeof currentSetupData !== 'undefined' && currentSetupData !== null
      && currentSetupData !== 'undefined') {
      try {
        const parsed = typeof currentSetupData === 'string'
          ? JSON.parse(currentSetupData)
          : currentSetupData;
        if (parsed) {
          dispatchSetupData({ type: 'set', payload: parsed });
          if (parsed.warn === 1) showCookiesWarn();
        }
      } catch (e) {
        showCookiesWarn();
      }
    } else {
      showCookiesWarn();
    }
    // eslint-disable-next-line
  }, []);

  useMemo(() => {
    setCookie('data', JSON.stringify(setupData), { path: '/' });
    // eslint-disable-next-line
  }, [setupData]);

  const data = { setupData, dispatchSetupData, termsAccepted, setTermsAccepted, resetSetupData };
  return (
    <SetupContext.Provider value={data}>
      {children}
    </SetupContext.Provider>
  );
};

SetupDataHolder.propTypes = {
  children: PropTypes.oneOfType(
    [PropTypes.object, PropTypes.element, PropTypes.arrayOf(PropTypes.element)]),
};

export default SetupDataHolder;
