import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { Columns, Form } from 'react-bulma-components';
import SetupContext from '../SetupContext';
import CustomCheckbox from '../checkbox/CustomCheckbox';

const General = ({ show }) => {
  const { setupData: data, dispatchSetupData: dispatchData } = useContext(SetupContext);

  const dispatchCheckbox = (checked, option) => {
    dispatchData({ type: 'general', option, value: checked ? 1 : 0 });
  };

  if (!show) return (<></>);

  console.table(data);

  return (
    <>
      <div className={'has-text-centered'}>
        <h2>General</h2>
        <b>These apply to all dashboards</b>
      </div>
      <br/><br/><br/>
      <Columns>
        <Columns.Column>
          <Form.Field>
            <Form.Label>Your app&apos;s name</Form.Label>
            <Form.Control>
              <Form.Input type={'text'} value={data.app}
                          placeholder={'My cool app'}
                          onChange={(e) => {
                            dispatchData({ type: 'app', payload: e.target.value });
                          }}/>
            </Form.Control>
          </Form.Field>
          <Form.Field>
            <Form.Label>Your name</Form.Label>
            <Form.Control>
              <Form.Input type={'text'} value={data.name}
                          placeholder={'Jon Appleseed'}
                          onChange={(e) => {
                            dispatchData({ type: 'name', payload: e.target.value });
                          }}/>
            </Form.Control>
          </Form.Field>
          <br/><br/>
          <CustomCheckbox label={'Enable notifications by default'} option={'notifications'}
                          checked={data.general.notifications === 1}
                          onChange={dispatchCheckbox}>
            <p className={'help'}>
              In case you want to enable&nbsp;
              <a href={'https://onesignal.com/'} rel={'noopener noreferrer'} target={'_blank'}>
                OneSignal
              </a> notifications
            </p>
          </CustomCheckbox>
          <br/>
          <CustomCheckbox label={'Enable animations by default'} option={'animations'}
                          checked={data.general.animations === 1}
                          onChange={dispatchCheckbox}/>
          <br/>
          <CustomCheckbox label={'Show app and dashboard versions in settings'} option={'versions'}
                          checked={data.general.versions === 1}
                          onChange={dispatchCheckbox}/>
          <br/><br/>
          <Form.Field>
            <Form.Label>Privacy Policy URL</Form.Label>
            <Form.Control>
              <Form.Input type={'text'} value={data.general.policy}
                          placeholder={'https://mywebsite.com/policy'}
                          onChange={(e) => {
                            dispatchData(
                              { type: 'general', option: 'policy', value: e.target.value });
                          }}/>
              <p className={'help'}>
                Generate one with&nbsp;
                <a href={'https://app-privacy-policy-generator.firebaseapp.com/'}
                   target={'_blank'} rel={'noopener noreferrer'}>
                  Privacy Policy Generator
                </a>
              </p>
            </Form.Control>
          </Form.Field>
          <Form.Field>
            <Form.Label>Terms and Conditions URL</Form.Label>
            <Form.Control>
              <Form.Input type={'text'} value={data.general.terms}
                          placeholder={'https://mywebsite.com/terms'}
                          onChange={(e) => {
                            dispatchData(
                              { type: 'general', option: 'terms', value: e.target.value });
                          }}/>
              <p className={'help'}>
                Generate one with&nbsp;
                <a href={'https://app-privacy-policy-generator.firebaseapp.com/'}
                   target={'_blank'} rel={'noopener noreferrer'}>
                  Privacy Policy Generator
                </a>
              </p>
            </Form.Control>
          </Form.Field>
          <br/>
          <Form.Field>
            <Form.Label>Default Theme</Form.Label>
            <Form.Control>
              <Form.Select value={data.general.theme}
                           onChange={(e) => {
                             dispatchData(
                               { type: 'general', option: 'theme', value: e.target.value });
                           }}>
                <option value={0}>Light</option>
                <option value={1}>Dark</option>
                <option value={2}>Follow system</option>
              </Form.Select>
              <p className={'help'}>Does not apply to launch screen</p>
            </Form.Control>
          </Form.Field>
          <CustomCheckbox label={'Use AMOLED/black theme by default'} option={'amoled'}
                          checked={data.general.amoled === 1}
                          onChange={dispatchCheckbox}>
            <p className={'help'}>Requires dark theme</p>
          </CustomCheckbox>
          <br/><br/>
          <Form.Field>
            <Form.Label>Donation items IDs</Form.Label>
            <Form.Control>
              <Form.Input type={'text'} value={data.general.donations}
                          placeholder={'coffee, pizza, burger'}
                          onChange={(e) => {
                            dispatchData(
                              { type: 'general', option: 'donations', value: e.target.value });
                          }}/>
              <p className={'help'}>
                Must be setup in&nbsp;
                <a href={'https://play.google.com/apps/publish/'}
                   target={'_blank'} rel={'noopener noreferrer'}>
                  Google Play Developer Console
                </a>
                &nbsp;first. Separate them with comma (,)
              </p>
            </Form.Control>
          </Form.Field>
        </Columns.Column>
        <Columns.Column/>
      </Columns>
    </>
  );
};

General.propTypes = {
  show: PropTypes.bool.isRequired,
};

export default General;
