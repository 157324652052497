import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Navbar } from 'react-bulma-components';
import { HashLink } from 'react-router-hash-link';
import MDIconText from '../mdi/MDIconText';

const Toolbar = () => {
  const [open, setOpen] = useState(false);
  const openMenu = () => setOpen(!open);

  return (
    <>
      <nav className={'navbar has-shadow is-fixed-top'}>
        <Navbar.Brand>
          <HashLink to={'/#top'} className={'navbar-item manrope'}>
            <img src={'https://jahir.dev/assets/images/projects/web/setup.png'}
                 alt={'DB'} height={36}/>
            &nbsp;&nbsp;&nbsp;
            <b><span className={'blue'}>Dashbud</span></b>
          </HashLink>
          {/* eslint-disable-next-line */}
          <button role={'button'} style={{ background: 'none', border: 'none' }}
                  className={`navbar-burger ${open ? 'is-active' : ''}`}
                  aria-label={'menu'}
                  aria-expanded={false}
                  onClick={openMenu}>
            <span aria-hidden={'true'}/>
            <span aria-hidden={'true'}/>
            <span aria-hidden={'true'}/>
          </button>
        </Navbar.Brand>
        <div className={`navbar-menu ${open ? 'is-active' : ''}`}>
          <Navbar.Container position={'end'}>
            <HashLink to={'/#frames'} className={'navbar-item'}>
              <MDIconText iconName={'image-outline'} text={'Frames'}/>
            </HashLink>
            <HashLink to={'/#kuper'} className={'navbar-item'}>
              <MDIconText iconName={'widgets-outline'} text={'Kuper'}/>
            </HashLink>
            <HashLink to={'/#blueprint'} className={'navbar-item'}>
              <MDIconText iconName={'view-dashboard-outline'} text={'Blueprint'}/>
            </HashLink>
            <Link to={'/studies'} className={'navbar-item'}>
              <MDIconText iconName={'material-design'} text={'Studies'}/>
            </Link>
            <Link to={'/setup'} className={'navbar-item'}>
              <MDIconText iconName={'cog-outline'} text={'Setup'}/>
            </Link>
          </Navbar.Container>
        </div>
      </nav>
    </>
  );
};

export default Toolbar;
