/* eslint-disable max-len */
class DashboardSetupFile {
  static buildFile(setup) {
    const donationsItemsTexts = (setup.donations || '').split(',')
      .map((it) => (`<item>${it.toString().trim()}</item>`))
      .join('\n\t');

    return `<?xml version="1.0" encoding="utf-8"?><!--suppress CheckTagEmptyBody -->
<resources xmlns:tools="http://schemas.android.com/tools" tools:ignore="MissingTranslation,ExtraTranslation">

    <!--
        Do you want to show a custom logo in toolbar?
        Create a new drawable and put its name here (without extension)
        Note: it will only be shown in the main section
    -->
    <string name="toolbar_logo"></string>

    <!--
    Do you want notifications to be enabled for all your users?
    True means yes
    False means no
    -->
    <bool name="notifications_enabled_by_default">${setup.notifications ? 'true' : 'false'}</bool>

    <!--
    Do you want animations to be enabled for all your users?
    True means yes
    False means no
    -->
    <bool name="animations_enabled_by_default">${setup.animations ? 'true' : 'false'}</bool>

    <!-- Privacy Policy link or url -->
    <string name="privacy_policy_link">"${setup.policy || ''}"</string>

    <!-- Terms and Conditions link or url -->
    <string name="terms_conditions_link">"${setup.terms || ''}"</string>

    <!--
    Do you want to show your app and the dashboard versions in settings?
    True means yes
    False means no
    -->
    <bool name="show_versions_in_settings">${setup.versions ? 'true' : 'false'}</bool>

    <!--
    Which theme do you want set as default?
    0. Light
    1. Dark
    2. Follow system
    -->
    <integer name="default_theme">${setup.theme || '0'}</integer>

    <!--
    Do you want AMOLED theme to be enabled by default? (Requires dark theme or system dark theme enabled)
    True means yes
    False means no
    -->
    <bool name="amoled_theme_enabled_by_default">${setup.amoled ? 'true' : 'false'}</bool>

    <!-- These are your donation items. Only put their ids here -->
    <string-array name="donation_items">
        ${donationsItemsTexts}
    </string-array>

</resources>`;
  }
}

export default DashboardSetupFile;
