import React, { useReducer } from 'react';
import PropTypes from 'prop-types';
import SnackbarContext from './SnackbarContext';

const snackbarReducer = (data, action) => {
  const actionType = action.type || 'show';
  switch (actionType) {
    case 'show':
      return {
        ...data,
        show: true,
        text: action.payload || action.text || '',
        style: action.style || '',
        duration: action.duration,
      };
    case 'hide':
      return { ...data, show: false };
    case 'removeText':
      return { ...data, show: false, text: '' };
    default:
      return data;
  }
};

const SnackbarHolder = (props) => {
  const [snackbarData, dispatchSnackbar] = useReducer(snackbarReducer,
    { show: false, text: '', style: '' });

  const data = {
    show: snackbarData.show,
    text: snackbarData.text,
    style: snackbarData.style,
    duration: snackbarData.duration,
    dispatchSnackbar,
  };

  return (
    <>
      <SnackbarContext.Provider value={data}>
        {props.children}
      </SnackbarContext.Provider>
    </>
  );
};

SnackbarHolder.propTypes = {
  children: PropTypes.any,
};

export default SnackbarHolder;
