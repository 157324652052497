/* eslint-disable max-len */
class StringsSetupFile {
  static buildFile(appName) {
    return `<?xml version="1.0" encoding="utf-8"?>
<resources>
    <string name="app_name">${appName}</string>
</resources>`;
  }
}

export default StringsSetupFile;
