import React from 'react';
import PropTypes from 'prop-types';

const MDIcon = ({
  iconName, iconSize = 18, onClick, className, style,
}) => (
  <span
    className={`mdi mdi-${iconSize}px mdi-${iconName}${onClick ? ' clickable' : ''}${className
      ? ` ${className}`
      : ''}`}
    onClick={onClick}
    style={style}/>
);

MDIcon.propTypes = {
  iconName: PropTypes.string.isRequired,
  iconSize: PropTypes.number,
  onClick: PropTypes.func,
  className: PropTypes.string,
  style: PropTypes.object,
};

export default MDIcon;
