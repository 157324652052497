import React, { useContext } from 'react';
import { Button } from 'react-bulma-components';
import MDIconText from '../mdi/MDIconText';
import SetupContext from './SetupContext';
import './setup.css';

const buttons = [
  { icon: 'information-outline', text: 'Disclaimer' },
  { icon: 'palette', text: 'Colors' },
  { icon: 'cog-outline', text: 'General' },
  { icon: 'image-outline', text: 'Frames' },
  { icon: 'widgets-outline', text: 'Kuper' },
  { icon: 'view-dashboard-outline', text: 'Blueprint' },
];

const SetupTabs = () => {
  const { setupData: data, dispatchSetupData: dispatchData } = useContext(SetupContext);
  const { tab: currentTab } = data;

  const setCurrentTab = (newTab) => dispatchData({ type: 'tab', payload: newTab });

  return (
    <div className={'buttons'}>
      {buttons.map((it, i) => (
        <Button key={i}
                onClick={() => { setCurrentTab(i); }} className={currentTab === i ? 'is-link' : ''}>
          <MDIconText iconName={it.icon} text={it.text}/>
        </Button>
      ))}
    </div>
  );
};

export default SetupTabs;
