import React from 'react';
import { Link } from 'react-router-dom';
import { Columns, Container } from 'react-bulma-components';
import Screenshot from '../carousel/Screenshot';
import MDIconText from '../mdi/MDIconText';

const Preview = () => (
  <Container>
    <div className={'has-text-centered use-manrope'}>
      <div className={'app-title'} style={{ marginBottom: 0, marginTop: 0 }}>
        <img className={'app-logo'} alt={'Dashbud Logo'}
             src={'https://jahir.dev/assets/images/projects/web/setup.png'}/>
        <h2>Dashbud</h2>
      </div>
      <br/>
      <p>This site is a landing page for 3 dashboards:&nbsp;
        <a href={'https://github.com/jahirfiquitiva/Frames'} target={'_blank'}
           rel={'noopener noreferrer'}>Frames</a>,&nbsp;
        <a href={'https://github.com/jahirfiquitiva/Kuper'}
           target={'_blank'}
           rel={'noopener noreferrer'}>Kuper</a> and <a
          href={'https://github.com/jahirfiquitiva/Blueprint'}
          target={'_blank'}
          rel={'noopener noreferrer'}>Blueprint</a>.</p>
      <p style={{ marginTop: '.6rem' }}>
        These dashboards were developed by <a href={'https://jahir.dev/'}
                                              target={'_blank'}
                                              rel={'noopener noreferrer'}>Jahir Fiquitiva</a>
      </p>
      <p style={{ marginTop: '.6rem' }}>
        Additionally, you can setup any of these dashboards by going to the&nbsp;
        <Link to={'/setup'}>setup site</Link>
      </p>
      <p style={{ marginTop: '.6rem' }}>
        For further support, feel free to <a href={'https://discordapp.com/invite/78h7xgj'}
                                             target={'_blank'}
                                             rel={'noopener noreferrer'}>join the Discord server</a>
      </p>
      <br/>
      <a className={'button use-manrope'}
         href={'https://discordapp.com/invite/78h7xgj'}
         target={'_blank'}
         rel={'noopener noreferrer'}
         style={{
           background: '#7289da', border: 'none', color: '#fff', fontWeight: 600,
         }}>
        <MDIconText text={'Join server'} iconName={'discord'}/>
      </a>
    </div>
    <br/><br/>
    <Columns>
      <Columns.Column>
        <img className={'app-logo'} alt={'FramesSetup Logo'}
             src={'https://raw.githubusercontent.com/jahirfiquitiva/Frames/master/art/app_logo.png'}/>
        <Screenshot imageUrl={'https://raw.githubusercontent.com/jahirfiquitiva/Frames/master/art/1.png'}
                    alt={'FramesSetup Preview'}/>
      </Columns.Column>
      <Columns.Column>
        <img className={'app-logo'} alt={'Kuper Logo'}
             src={'https://raw.githubusercontent.com/jahirfiquitiva/Kuper/master/art/app_logo.png'}/>
        <Screenshot imageUrl={'https://raw.githubusercontent.com/jahirfiquitiva/Kuper/master/art/1.png'}
                    alt={'FramesSetup Preview'}/>
      </Columns.Column>
      <Columns.Column>
        <img className={'app-logo'} alt={'Blueprint Logo'}
             src={'https://raw.githubusercontent.com/jahirfiquitiva/Blueprint/master/art/app_logo.png'}/>
        <Screenshot imageUrl={'https://raw.githubusercontent.com/jahirfiquitiva/Blueprint/master/art/1.png'}
                    alt={'FramesSetup Preview'}/>
      </Columns.Column>
    </Columns>
  </Container>
);

export default Preview;
