import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Feature from './Feature';
import './features.css';

const maxItemsPerPage = 3;

const BaseFeaturesList = ({ features }) => {
  const [currentPage, setCurrentPage] = useState(0);
  const pages = Math.ceil(features.length / maxItemsPerPage);

  const goToPrevPage = () => {
    setCurrentPage((page) => (page <= 0 ? pages - 1 : page - 1));
  };

  const goToNextPage = () => {
    setCurrentPage((page) => ((page + 1) > pages - 1 ? 0 : page + 1));
  };

  const getPageItems = () => {
    const max = (features || []).length || 0;
    const first = currentPage * maxItemsPerPage;
    const last = (currentPage + 1) * maxItemsPerPage;
    return (features || []).slice(first <= 0 ? 0 : first, last >= max ? max : last);
  };

  const renderArrows = () => {
    if (pages <= 1) return (<></>);
    return (
      <div className={'features-arrows'}>
        <span className={'mdi mdi-24px mdi-arrow-left'}
              onClick={goToPrevPage}
              style={{ cursor: 'pointer' }}/>
        <p>{currentPage + 1}/{pages}</p>
        <span className={'mdi mdi-24px mdi-arrow-right'}
              onClick={goToNextPage}
              style={{ cursor: 'pointer' }}/>
      </div>
    );
  };

  return (
    <div className={'has-same-height'}>
      <h4 className={'has-text-centered'}>Features</h4>
      <br/>
      <div className={'features-arrows-container'}>
        {renderArrows()}
      </div>
      <div className={'features'}>
        {getPageItems().map((it, i) => (<Feature key={i} feature={it}/>))}
      </div>
    </div>
  );
};

BaseFeaturesList.propTypes = {
  features: PropTypes.array.isRequired,
};

export default BaseFeaturesList;
