import React, { useContext } from 'react';
import { PropTypes } from 'prop-types';
import { HashLink } from 'react-router-hash-link';
import MDIconText from '../../mdi/MDIconText';
import SetupContext from '../SetupContext';
import CustomCheckbox from '../checkbox/CustomCheckbox';

const Disclaimer = ({ show }) => {
  const { termsAccepted, setTermsAccepted, resetSetupData } = useContext(SetupContext);

  const setTerms = (checked) => { setTermsAccepted(checked); };

  const resetSetup = () => {
    if (window.confirm('Are you sure you want to reset the setup?')) {
      resetSetupData();
    }
  };

  if (!show) return (<></>);

  return (
    <>
      <div className={'has-text-centered'}>
        <h1>Disclaimer</h1>
        <b>Please read carefully</b>
      </div>
      <br/><br/><br/>
      <p>After many requests and reports of my dashboards being &quot;too hard&quot; to setup, I
         decided to create this website as a tool to help you get started using any of my
         dashboards (<HashLink to={'/#frames'}>Frames</HashLink>,&nbsp;
        <HashLink to={'/#kuper'}>Kuper</HashLink> and <HashLink
          to={'/#blueprint'}>Blueprint</HashLink>).
      </p>
      <br/>
      <p>
        I have really spent a long time working on my dashboards and also this website, so I really
        want to kindly ask you to support my work by doing a small donation to me.
        Whatever you help me with, I will appreciate it a lot!&nbsp;&nbsp;
        <span role={'img'} aria-label={'praying emoji'}>🙏🏼</span>
      </p>
      <br/>
      <a className={'button is-link'} rel={'noopener noreferrer'} target={'_blank'}
         href={'https://jahir.dev/donate'}>
        <MDIconText text={'Support development'} iconName={'gift-outline'}/>
      </a>
      <br/><br/><br/>
      <h3>Do I have to fill all the fields and forms in this website?</h3>
      <br/>
      <h5><b>TL;DR: NO <span role={'img'} aria-label={'sweat smile emoji'}>😅</span></b></h5>
      <br/>
      <p>
        This website contains all the default options from the dashboards already set. Which means,
        whatever you don&apos;t want to change, you can just ignore it.
        The website will still generate the files correctly.
      </p>
      <p>
        Anyway, I <b>highly</b> recommend you to check all sections so you don&apos;t miss anything.
      </p>
      <br/><br/>
      <h3>What will I get with this?</h3>
      <br/>
      <p>With this website you will get the following configuration files:</p>
      <br/>
      <div className={'table-container'}>
        <table className={'table is-bordered is-striped is-narrow is-hoverable'}>
          <thead>
            <tr>
              <th>File name</th>
              <th>Used in…</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td><code>colors.xml</code></td>
              <td>All dashboards</td>
            </tr>
            <tr>
              <td><code>strings.xml</code></td>
              <td>All dashboards</td>
            </tr>
            <tr>
              <td><code>dashboard_setup.xml</code></td>
              <td>All dashboards</td>
            </tr>
            <tr>
              <td><code>frames_setup.xml</code></td>
              <td>All dashboards</td>
            </tr>
            <tr>
              <td><code>kuper_setup.xml</code></td>
              <td>Kuper and Blueprint</td>
            </tr>
            <tr>
              <td><code>blueprint_setup.xml</code></td>
              <td>Blueprint</td>
            </tr>
            <tr>
              <td><code>home_setup.xml</code></td>
              <td>Blueprint</td>
            </tr>
          </tbody>
        </table>
      </div>
      <br/><br/>
      <h3>Additional Setup</h3>
      <br/>
      <p>After you download the generated zip file, you just have to extract the files
         inside, in the <code>/app/src/main/res/values</code> folder of your project
         and <b>overwrite</b> any previous file in it.</p>
      <p>This tool does <b>not</b> replace <b>in any way</b> the Android Studio and project setup
         process. It just helps making it a little easier.</p>
      <p>As much as I wanted this tool to offer all possible options, there are some things that
         cannot be put here due to their complexity.</p>
      <br/>
      <p>So, after exporting/downloading the configuration files provided by this tool,
         you <b>must</b> check them, because they can contain things that are empty and that&nbsp;
        <b>must be filled manually</b> by yourself.
      </p>
      <br/>
      <p>Please visit the respective dashboard wiki site for guidance:</p>
      <ul style={{ listStyle: 'disc', paddingInlineStart: '1.2rem' }}>
        <li>
          <a target={'_blank'} rel={'noopener noreferrer'}
             href={'https://github.com/jahirfiquitiva/Frames/wiki/'}>
            Frames
          </a>
        </li>
        <li>
          <a target={'_blank'} rel={'noopener noreferrer'}
             href={'https://github.com/jahirfiquitiva/Kuper/wiki/'}>
            Kuper
          </a>
        </li>
        <li>
          <a target={'_blank'} rel={'noopener noreferrer'}
             href={'https://github.com/jahirfiquitiva/Blueprint/wiki/'}>
            Blueprint
          </a>
        </li>
      </ul>
      <br/><br/>
      <h3>Information Privacy and Security</h3>
      <br/>
      <p>
        All the information provided by you in this website is only available for this website.
        Nothing is shared anywhere.
        <br/>
        This site uses cookies to save your current setup so you don&apos;t lose your progress.
        <br/>
        In case you need to, you can always reset all fields by clicking the button below.
      </p>
      <br/>
      <button className={'button use-manrope is-danger'} onClick={resetSetup}
              style={{ background: '#d43551', color: '#fff', fontWeight: 600 }}>
        <MDIconText text={'Reset setup'} iconName={'delete-forever-outline'}/>
      </button>
      <br/><br/><br/>
      <h3>Confirmation</h3>
      <br/>
      <CustomCheckbox label={'I accept and understand the things mentioned above'}
                      checked={termsAccepted} option={'terms'} onChange={setTerms}/>
      <br/><br/>
      <p style={{ marginTop: '.6rem' }}>
        For further support, feel free to <a href={'https://discordapp.com/invite/78h7xgj'}
                                             target={'_blank'}
                                             rel={'noopener noreferrer'}>join the Discord
                                                                         server</a>
      </p>
      <br/>
      <a className={'button use-manrope'}
         href={'https://discordapp.com/invite/78h7xgj'}
         target={'_blank'} rel={'noopener noreferrer'}
         style={{
           background: '#7289da', border: 'none', color: '#fff', fontWeight: 600,
         }}>
        <MDIconText text={'Join server'} iconName={'discord'}/>
      </a>
    </>
  );
};

Disclaimer.propTypes = {
  show: PropTypes.bool.isRequired,
};

export default Disclaimer;
