import React from 'react';
import PropTypes from 'prop-types';
import { Box, Columns } from 'react-bulma-components';
import ThemeColors from '../theme/ThemeColors';

const Colors = ({ show }) => {
  if (!show) return (<></>);

  return (
    <>
      <div className={'has-text-centered'}>
        <h1>Colors</h1>
      </div>
      <br/><br/>
      <Columns>
        <Columns.Column>
          <Box>
            <ThemeColors name={'Light'}/>
          </Box>
        </Columns.Column>
        <Columns.Column>
          <Box>
            <ThemeColors name={'Dark'}/>
          </Box>
        </Columns.Column>
        <Columns.Column>
          <Box>
            <ThemeColors name={'Amoled'}/>
          </Box>
        </Columns.Column>
      </Columns>
    </>
  );
};

Colors.propTypes = {
  show: PropTypes.bool,
};

export default Colors;
