import React from 'react';
import { Route, Switch } from 'react-router-dom';
import MainSite from './sites/MainSite';
import NotFound from './sites/NotFound';
import Studies from './studies/Studies';
import Setup from './sites/Setup';

const Website = () => (
  <Switch>
    <Route exact path={'/'} component={MainSite}/>
    <Route exact path={'/studies'} component={Studies}/>
    <Route exact path={'/setup'} component={Setup}/>
    <Route><NotFound/></Route>
  </Switch>
);

export default Website;
