import React from 'react';
import PropTypes from 'prop-types';
import { Box } from 'react-bulma-components';
import MDIconText from '../mdi/MDIconText';

const defaultIcon = 'material-design';
const defaultTitle = 'This is a title';
const defaultDescription = 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. '
  + 'Nulla molestie nulla mollis felis rhoncus vulputate.';

const Feature = ({ feature }) => (
  <Box style={{ marginLeft: '.6rem', marginRight: '.6rem', opacity: feature ? 1 : 0 }}
       className={'feature'}>
    <MDIconText bold text={feature ? feature.title || defaultTitle : defaultTitle}
                iconName={feature ? feature.icon || defaultIcon : defaultIcon}
                iconSize={24} className={'feature-title'}/>
    <p>{feature ? feature.description || defaultDescription : defaultDescription}</p>
  </Box>
);

Feature.propTypes = {
  feature: PropTypes.object.isRequired,
};

export default Feature;
