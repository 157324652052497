import React from 'react';
import { Columns, Container } from 'react-bulma-components';
import BlueprintCarousel from '../carousel/BlueprintCarousel';
import BlueprintFeatures from '../features/BlueprintFeatures';
import './app-title.css';

const Blueprint = () => (
  <Container>
    <div className={'app-title'}>
      <img className={'app-logo'} alt={'Blueprint Logo'}
           src={'https://raw.githubusercontent.com/jahirfiquitiva/Blueprint/master/art/app_logo.png'}/>
      <h3>Blueprint</h3>
    </div>
    <Columns>
      <Columns.Column>
        <BlueprintCarousel/>
      </Columns.Column>
      <Columns.Column>
        <BlueprintFeatures/>
      </Columns.Column>
    </Columns>
    <br/>
  </Container>
);

export default Blueprint;
