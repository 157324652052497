import React from 'react';
import { Columns, Container } from 'react-bulma-components';
import KuperCarousel from '../carousel/KuperCarousel';
import KuperFeatures from '../features/KuperFeatures';
import './app-title.css';

const Kuper = () => (
  <Container>
    <div className={'app-title'}>
      <img className={'app-logo'} alt={'Kuper Logo'}
           src={'https://raw.githubusercontent.com/jahirfiquitiva/Kuper/master/art/app_logo.png'}/>
      <h3>Kuper</h3>
    </div>
    <Columns>
      <Columns.Column>
        <KuperCarousel/>
      </Columns.Column>
      <Columns.Column>
        <KuperFeatures/>
      </Columns.Column>
    </Columns>
    <br/>
  </Container>
);

export default Kuper;
