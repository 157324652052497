import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import Website from './components/website';
import SnackbarHolder from './components/snackbar/SnackbarHolder';
import Snackbar from './components/snackbar/Snackbar';
import './App.css';

const App = () => (
  <BrowserRouter>
    <SnackbarHolder>
      <Website/>
      <Snackbar/>
    </SnackbarHolder>
  </BrowserRouter>
);

export default App;
